// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type UserByTypeCountVariables = Types.Exact<{ [key: string]: never; }>;


export type UserByTypeCount = { __typename: 'Query', userByTypeCount?: { __typename: 'UserCountByType', allCount?: number | null, akCount?: number | null, clientCount?: number | null, dealerCount?: number | null, molchunCount?: number | null, mopLkCount?: number | null, noNameCount?: number | null, partsCount?: number | null, rabotaCount?: number | null, spamCount?: number | null, torgashCount?: number | null, _id: any } | null };


export const UserByTypeCountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UserByTypeCount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userByTypeCount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"allCount"}},{"kind":"Field","name":{"kind":"Name","value":"akCount"}},{"kind":"Field","name":{"kind":"Name","value":"clientCount"}},{"kind":"Field","name":{"kind":"Name","value":"dealerCount"}},{"kind":"Field","name":{"kind":"Name","value":"molchunCount"}},{"kind":"Field","name":{"kind":"Name","value":"mopLkCount"}},{"kind":"Field","name":{"kind":"Name","value":"noNameCount"}},{"kind":"Field","name":{"kind":"Name","value":"partsCount"}},{"kind":"Field","name":{"kind":"Name","value":"rabotaCount"}},{"kind":"Field","name":{"kind":"Name","value":"spamCount"}},{"kind":"Field","name":{"kind":"Name","value":"torgashCount"}},{"kind":"Field","name":{"kind":"Name","value":"_id"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useUserByTypeCount__
 *
 * To run a query within a React component, call `useUserByTypeCount` and pass it any options that fit your needs.
 * When your component renders, `useUserByTypeCount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByTypeCount({
 *   variables: {
 *   },
 * });
 */
export function useUserByTypeCount(baseOptions?: ApolloReactHooks.QueryHookOptions<UserByTypeCount, UserByTypeCountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserByTypeCount, UserByTypeCountVariables>(UserByTypeCountDocument, options);
      }
export function useUserByTypeCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserByTypeCount, UserByTypeCountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserByTypeCount, UserByTypeCountVariables>(UserByTypeCountDocument, options);
        }
export type UserByTypeCountHookResult = ReturnType<typeof useUserByTypeCount>;
export type UserByTypeCountLazyQueryHookResult = ReturnType<typeof useUserByTypeCountLazyQuery>;
export type UserByTypeCountQueryResult = Apollo.QueryResult<UserByTypeCount, UserByTypeCountVariables>;