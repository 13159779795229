import { useUser } from '@clerk/clerk-react';
import { useEffect, useState } from 'react';

const RoleProvider = () => {
  const { user, isLoaded } = useUser();
  const [userRole, setUserRole] = useState<string>(localStorage.getItem('userRole') || '');
  useEffect(() => {
    if (isLoaded && user?.organizationMemberships[0]?.role) {
      localStorage.setItem('userRole', user.organizationMemberships[0].role.slice(4));
      setUserRole(user.organizationMemberships[0].role.slice(4));
    }
    if (isLoaded && !user?.organizationMemberships[0]?.role) {
      localStorage.removeItem('userRole');
    }
  }, [isLoaded, user]);
  return userRole;
};

export default RoleProvider;

export const userRole = localStorage.getItem('userRole');
