import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

interface FilterContextProps {
  filter: string;
  setFilter: (filter: string) => void;
  hostFilter: string;
  setHostFilter: (filter: string) => void;
  chatsFilter: string;
  setChatsFilter: (filter: string) => void;
  nodesFilter: string;
  setNodesFilter: (filter: string) => void;
  authFilter: string;
  setAuthFilter: (filter: string) => void;
  typificationFilter: string;
  setTypificationFilter: (filter: string) => void;
}
interface FilterProviderProps {
  children: ReactNode;
}

const FilterContext = createContext({} as FilterContextProps);

export const FilterProvider = ({ children }: FilterProviderProps) => {
  const [filter, setFilter] = useState(() => localStorage.getItem('filter') ?? '');
  const [hostFilter, setHostFilter] = useState(() => localStorage.getItem('hostFilter') ?? '');
  const [chatsFilter, setChatsFilter] = useState(() => localStorage.getItem('chatsFilter') ?? '');
  const [nodesFilter, setNodesFilter] = useState(() => localStorage.getItem('nodesFilter') ?? '');
  const [authFilter, setAuthFilter] = useState(() => localStorage.getItem('authFilter') ?? '');
  const [typificationFilter, setTypificationFilter] = useState(
    () => localStorage.getItem('typificationFilter') ?? ''
  );

  useEffect(() => {
    localStorage.setItem('filter', filter);
  }, [filter]);

  useEffect(() => {
    localStorage.setItem('hostFilter', hostFilter);
  }, [hostFilter]);

  useEffect(() => {
    localStorage.setItem('chatsFilter', chatsFilter);
  }, [chatsFilter]);

  useEffect(() => {
    localStorage.setItem('nodesFilter', nodesFilter);
  }, [nodesFilter]);

  useEffect(() => {
    localStorage.setItem('authFilter', authFilter);
  }, [authFilter]);

  useEffect(() => {
    localStorage.setItem('typificationFilter', typificationFilter);
  }, [typificationFilter]);

  return (
    <FilterContext.Provider
      value={{
        filter,
        setFilter,
        hostFilter,
        setHostFilter,
        chatsFilter,
        setChatsFilter,
        nodesFilter,
        setNodesFilter,
        authFilter,
        setAuthFilter,
        typificationFilter,
        setTypificationFilter,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => useContext(FilterContext);
