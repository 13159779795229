import RoleProvider from 'app/providers/roleProvider/roleProvider';
import { AuthorizePageLazy } from 'pages/authorizePage/authorizePageLazy';
import { BaseCreatePageLazy } from 'pages/baseCreate/baseCreatePageLazy';
import { BestOfferPageLazy } from 'pages/bestOffer/bestOfferPageLazy';
import { BroadcastPageLazy } from 'pages/broadcastPage/broadcastPageLazy';
import { NodesPageLazy } from 'pages/nodesPage/nodesPageLazy';
import { TypificationPageLazy } from 'pages/typificationPage/typificationPageLazy';
import { WaChatsPageLazy } from 'pages/waChatsPage/waChatsPageLazy';
import { WithoutRoleUserPageLazy } from 'pages/withoutRoleUser/withoutRoleUserPageLazy';
import { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

export const AppRouter = () => {
  const userRole = RoleProvider();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!userRole || userRole === '' || userRole === null || userRole === undefined) {
      navigate('/access-denied');
    }
    if (location.pathname.includes('admin') && userRole !== 'admin') {
      navigate('/access-denied');
    }
  }, [navigate, userRole, location.pathname]);
  return (
    <Suspense fallback={<></>}>
      <Routes>
        <Route path="/" element={<Navigate to="/wachats" />} />
        <Route path="/wachats/:account?/:chat?" element={<WaChatsPageLazy />} />
        <Route path="/broadcast/:broadcastid?/:client?" element={<BroadcastPageLazy />} />
        <Route path="/broadcast/:broadcastid/edit" element={<BroadcastPageLazy />} />
        <Route path="/broadcast/create" element={<BroadcastPageLazy />} />
        <Route path="typification" element={<TypificationPageLazy />} />
        <Route path="best-offer" element={<BestOfferPageLazy />} />
        <Route path="base-create" element={<BaseCreatePageLazy />} />
        <Route path="/admin">
          <Route path="nodes" element={<NodesPageLazy />}>
            <Route path=":node" element={<NodesPageLazy />} />
          </Route>
          <Route path="authorize" element={<AuthorizePageLazy />} />
        </Route>
        <Route
          path="/access-denied"
          element={
            !localStorage.getItem('userRole') ? <WithoutRoleUserPageLazy /> : <Navigate to="/" />
          }
        />
      </Routes>
    </Suspense>
  );
};
