import { EnumUserType } from '__generated__/types';
import { memo } from 'react';
import TypificationFilter from 'shared/components/typificationFilter';
import { useUserByTypeCount } from './__generated__/UserByTypeCountQuery';

const TypificationFilters = memo(() => {
  const { data: clientsCount } = useUserByTypeCount();

  return (
    <div className="mt-4 grid grid-cols-2 gap-2">
      <TypificationFilter
        className={'col-span-2'}
        type="ВСЕ КЛИЕНТЫ"
        allCount={clientsCount?.userByTypeCount?.allCount || 0}
      />
      {Object.entries(EnumUserType).map(([, type], index) => (
        <TypificationFilter
          clientsTypeCount={clientsCount?.userByTypeCount}
          key={index}
          type={type}
        />
      ))}
    </div>
  );
});

export default TypificationFilters;
