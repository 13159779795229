import { memo } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

interface NavLinkProps {
  href: string;
  children: React.ReactNode;
  className?: string;
}

export const NavLink = memo(({ href, children, className }: NavLinkProps) => {
  const pathname = useLocation().pathname;
  const { node } = useParams();
  const isActive = pathname.includes(href) || pathname === href;
  return (
    <Link
      to={href}
      className={`${className} button dark:bg-surfaceLighter} bg-surfaceDarker ${
        !node && isActive ? 'activeLink' : 'inactiveLink'
      }`}
    >
      {children}
    </Link>
  );
});
