import { useLocation } from 'react-router-dom';
import SideMenu from 'shared/components/sideMenu';
import Header from 'widgets/header';

export default function Layout({ children }: { children: React.ReactNode }) {
  const userRole = localStorage.getItem('userRole');
  const location = useLocation();
  const pathname = location.pathname;
  const isAccessDenied = pathname.startsWith('/access-denied') || pathname === '/access-denied';

  return (
    <>
      <Header />
      <main
        className={`${isAccessDenied ? 'flex' : 'grid'} h-[calc(100vh-110px)] w-full grid-flow-col grid-cols-[0.17fr_1fr] gap-x-2 px-[1%] xl:gap-x-6`}
      >
        {userRole && (
          <div className="scrollable h-[calc(100vh-110px)] min-w-[220px] rounded-2xl bg-white p-4 shadow-sm dark:bg-surfaceDark">
            <SideMenu />
          </div>
        )}
        <div className={`flex h-[calc(100vh-110px)] w-full flex-row gap-x-2 xl:gap-x-6`}>
          {children}
        </div>
      </main>
    </>
  );
}
