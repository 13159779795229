// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type ConnectionSessionWACountVariables = Types.Exact<{ [key: string]: never; }>;


export type ConnectionSessionWACount = { __typename: 'Query', connectionSessionWACount?: Array<{ __typename: 'WhatsappCount', status?: string | null, allCount?: number | null, activeCount?: number | null, host?: string | null } | null> | null };


export const ConnectionSessionWACountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ConnectionSessionWACount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"connectionSessionWACount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"allCount"}},{"kind":"Field","name":{"kind":"Name","value":"activeCount"}},{"kind":"Field","name":{"kind":"Name","value":"host"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useConnectionSessionWACount__
 *
 * To run a query within a React component, call `useConnectionSessionWACount` and pass it any options that fit your needs.
 * When your component renders, `useConnectionSessionWACount` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionSessionWACount({
 *   variables: {
 *   },
 * });
 */
export function useConnectionSessionWACount(baseOptions?: ApolloReactHooks.QueryHookOptions<ConnectionSessionWACount, ConnectionSessionWACountVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ConnectionSessionWACount, ConnectionSessionWACountVariables>(ConnectionSessionWACountDocument, options);
      }
export function useConnectionSessionWACountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConnectionSessionWACount, ConnectionSessionWACountVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ConnectionSessionWACount, ConnectionSessionWACountVariables>(ConnectionSessionWACountDocument, options);
        }
export type ConnectionSessionWACountHookResult = ReturnType<typeof useConnectionSessionWACount>;
export type ConnectionSessionWACountLazyQueryHookResult = ReturnType<typeof useConnectionSessionWACountLazyQuery>;
export type ConnectionSessionWACountQueryResult = Apollo.QueryResult<ConnectionSessionWACount, ConnectionSessionWACountVariables>;