import { Badge, ConfigProvider } from 'antd';
import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useFilter } from 'utils/filterContext';

interface AccountFilterProps {
  linkFilter: string;
  className?: string;
  allAccounts?: number;
  activeAccounts?: number;
}

export const AccountFilter = memo((props: AccountFilterProps) => {
  const { linkFilter, className, allAccounts, activeAccounts } = props;

  const hostFilters = {
    PC_01: 'PC01',
    PC_02: 'PC02',
    PC_03: 'PC03',
    PC_04: 'PC04',
    Phones: 'Phone',
  };

  const { filter, setFilter, authFilter, setAuthFilter, hostFilter, setHostFilter } = useFilter();
  const location = useLocation();
  const isAuthorizePage =
    location.pathname === '/authorize' || location.pathname.includes('authorize');

  const handleFilterClick = (newFilter: string) => {
    if (isAuthorizePage) setAuthFilter(filter === linkFilter ? '' : newFilter);
    if (newFilter in hostFilters)
      setHostFilter(
        hostFilter === linkFilter.replace('_', '') ||
          hostFilter === linkFilter.replace('Phones', 'Phone')
          ? ''
          : hostFilters[newFilter as keyof typeof hostFilters]
      );
    else setFilter(filter === linkFilter ? '' : newFilter);
  };

  return (
    <div className={`${className}`}>
      <ConfigProvider
        theme={{
          components: {
            Badge: {
              indicatorZIndex: 10,
              textFontWeight: '500',
            },
          },
          token: {
            colorBorderBg: 'transparent',
          },
        }}
      >
        <Badge
          count={(activeAccounts || allAccounts) && activeAccounts + '/' + allAccounts}
          size="small"
          className="w-full select-none"
          style={{ color: '#fefefe' }}
          color="#2a3942"
          overflowCount={400}
          offset={[-9, 0]}
        >
          <button
            onClick={() => handleFilterClick(linkFilter)}
            className={`${className} flex w-full select-none justify-center whitespace-nowrap rounded-2xl px-2 py-[9px] font-semibold duration-200 dark:text-white ${
              (!isAuthorizePage && filter === linkFilter) ||
              (isAuthorizePage && authFilter === linkFilter) ||
              hostFilter === linkFilter.replace('_', '') ||
              hostFilter === linkFilter.replace('Phones', 'Phone')
                ? 'activeLink'
                : 'inactiveLink'
            }`}
          >
            <p className="select-none">{linkFilter}</p>
          </button>
        </Badge>
      </ConfigProvider>
    </div>
  );
});
