import { AccountFiltersList } from 'features/waAccounts/accountFilters';
import TypificationFilters from 'features/waTypification/typificationFilters';
import { motion } from 'framer-motion';
import { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Plus } from 'shared/assets/icons/broadcast/icons';
import {
  Base,
  Broadcast,
  Node,
  Proposal,
  QrCode,
  Typification,
  WhatsApp,
} from 'shared/assets/icons/icons';
import NavLink from 'shared/components/navLink';

const SideMenu = () => {
  const userRole = localStorage.getItem('userRole');
  const pathname = useLocation().pathname;
  const isWaChatsPage = pathname.startsWith('/wachats/') || pathname === '/wachats';
  const isBroadcastPage = pathname.startsWith('/broadcast/') || pathname === '/broadcast';
  const isTypificationPage = pathname.startsWith('/typification/') || pathname === '/typification';
  const isBroadcastCreatePage =
    pathname.startsWith('/broadcast/create') || pathname === '/broadcast/create';

  return (
    <div className="flex flex-col gap-y-2">
      <NavLink href="/wachats" className="p-2">
        <div className="relative flex w-full flex-row items-center gap-x-3">
          <WhatsApp className="size-8" />
          <p className="select-none">Мессенджер</p>
        </div>
      </NavLink>
      <div className="relative flex flex-col">
        <NavLink
          href="/broadcast"
          className={`relative z-30 ${isBroadcastPage && !isBroadcastCreatePage ? 'mb-8' : ''} p-2`}
        >
          <div className="flex w-full flex-row items-center gap-x-3">
            <Broadcast className="size-8" />
            <p className="select-none">Рассылка</p>
          </div>
        </NavLink>
        {isBroadcastPage && !isBroadcastCreatePage ? (
          <Link to="/broadcast/create" className="absolute top-8 w-full">
            <motion.button
              key={'create'}
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              className="mx-auto flex h-12 w-[90%] select-none items-end justify-center rounded-2xl bg-surfaceLight px-3 py-1 text-sm font-semibold duration-150 hover:bg-activeLinkLight dark:bg-surfaceLighter dark:text-white dark:hover:bg-activeLinkDark"
            >
              <div className="flex flex-row items-center">
                <p className="whitespace-normal py-1 xl:whitespace-nowrap dark:text-white">
                  Создать рассылку
                </p>
                <Plus className="ml-4 hidden size-6 2xl:block" />
              </div>
            </motion.button>
          </Link>
        ) : null}
      </div>
      <NavLink href="/base-create" className={`relative z-30 p-2`}>
        <div className="flex w-full flex-row items-center gap-x-3">
          <Base className="size-8" />
          <p className="select-none leading-tight">Создание базы</p>
        </div>
      </NavLink>
      {userRole === 'admin' && (
        <div className="flex flex-row gap-x-2">
          <NavLink href="/admin/nodes" className="w-full p-2">
            <Node className="size-8" />
          </NavLink>
          <NavLink href="/admin/authorize" className="w-full p-2">
            <QrCode className="size-8" />
          </NavLink>
        </div>
      )}
      <NavLink href="/typification" className={`relative z-30 p-2`}>
        <div className="flex w-full flex-row items-center gap-x-3">
          <Typification className="size-8" />
          <p className="select-none leading-tight">Типизация пользователей</p>
        </div>
      </NavLink>
      <NavLink href="/best-offer" className={`relative z-30 p-2`}>
        <div className="flex w-full flex-row items-center gap-x-3">
          <Proposal className="size-8" />
          <p className="select-none leading-tight">Крутое предложение</p>
        </div>
      </NavLink>

      {isWaChatsPage && userRole === 'admin' && <AccountFiltersList />}
      {isTypificationPage && <TypificationFilters />}
    </div>
  );
};

export default memo(SideMenu);
