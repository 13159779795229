export const FilterIcons = {
  POISK: '😎',
  OPBD: '🤑',
  TRUCKHOLDING: '🤠',
  PEOPLE: '🤵‍♂️',
  NOVOREG: '⚪',
  NEW: '🟡',
  WMMAIL: '🟣',
  PROGREV: '🔵',
  PROD: '🟢',
  BANNED: '❌',
  QR: '🔴',
  BOSS: '👑',
  IT: '👨‍💻',
};
